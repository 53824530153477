import React, { useState } from 'react'
import styled from 'styled-components'
import AgoraUIKit from 'agora-react-uikit'
import { TherapistToast } from 'helpers'
import { HMSPrebuilt } from '@100mslive/roomkit-react';
import useFetchAccount from 'hooks/account/useFetchAccount';


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

interface VoiceProps {
  token: string
  channelName: string
  handleEndCall: (type: 'voice' | 'video' | 'chat') => void
  SDKToUse: string
  name: string
  roomCode: string
}
const VoiceChat: React.FC<VoiceProps> = ({
  token,
  channelName,
  handleEndCall,
  SDKToUse,
  name,
  roomCode
}) => {

  const { data: account } = useFetchAccount()
  const therapistName = account?.name


  const [voiceCall, setVoiceCall] = useState(true)
  const rtcProps = {
    appId: '4d3643b1f564412f8c65ac88e85aaf20',
    channel: channelName,
    videoCall: false,
    voiceCall: voiceCall,
    token: token, // use null or skip if using app in testing mode
  }
  const callbacks = {
    EndCall: () => {
      handleEndCall('chat')
      TherapistToast.success({ msg: 'Voice call Ended' })
      setVoiceCall(false)
    },
  }
  return voiceCall ? (
    <Wrapper>
      {
        SDKToUse === "agora" ?
          <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} />
          :
          <HMSPrebuilt
            onLeave={callbacks.EndCall}
            roomCode={roomCode}
            options={{
              userName: therapistName
            }}
          />
      }
    </Wrapper>
  ) : (
    <h3 onClick={() => setVoiceCall(true)}>Start Call</h3>
  )
}

export default VoiceChat
